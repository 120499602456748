import { useContext } from 'react';
import { SnackbarContext } from '../components/SnackbarProvider';

/**
 * Hook to use the Snackbar context.
 *
 * @returns {{
 *  showSuccessSnackbar: (message: string) => void,
 *  showErrorSnackbar: (message: string) => void,
 * }}
 *   - `showSuccessSnackbar`: Function to display a success message.
 *   - `showErrorSnackbar`: Function to display an error message.
 *
 * @example
 * const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
 *
 * const onDeleteRecord = async recordId => {
 *  try {
 *    const deleteResponse = await deleteRecordById(recordId)
 *    showSuccessSnackbar(
 *      `Record with ${recordId} id was successfully deleted.`
 *    );
 *  } catch (err) {
 *    showErrorSnackbar(
 *      `Cannot delete record with ${recordId} id.`
 *    );
 *  }
 * }
 */
const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  return context;
};

export default useSnackbar;
