import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import MultiSelect from '@components/MultiSelect';
import GENERIC_CONSTANTS from '@constants/Regulations/Generic';
import { useStates, useCounties, useCities } from '@hooks/locations';
import { TextareaWithActions } from './TextareaWithActions';
import { useMutation } from '@tanstack/react-query';
import { createRegion, Region } from 'api/locations';
import axios from 'axios';
import { useSelector } from 'react-redux';
import React from 'react';


const zipcodesToArray = (zipcodes: string): string[] => {
  if (!zipcodes) return [];

  return zipcodes
    .split(',')
    .map((zip) => zip.trim())
    .filter(Boolean);
}

const createRegionValidationSchema = Yup.object({
  regionName: Yup.string().min(3).max(50).required(GENERIC_CONSTANTS.REGION_NAME_IS_REQUIRED),
  countryCode: Yup.string().required(),
  stateCode: Yup.string().required(GENERIC_CONSTANTS.STATE_IS_REQUIRED),
  counties: Yup.array().of(Yup.string()),
  cities: Yup.array().of(Yup.string()),
  zipcodes: Yup.string()
    .trim()
    .test('valid-zipcodes', GENERIC_CONSTANTS.INVALID_ZIPCODES_FORMAT, (value) => {
      if (!value) return true;

      return zipcodesToArray(value).every((zip) => zip.length === 5);
    })
    .test('unique-zipcodes', GENERIC_CONSTANTS.ZIPCODES_MUST_BE_UNIQUE, (value) => {
      if (!value) return true;

      const zipcodes = zipcodesToArray(value);
      const unqiueZipcodes = new Set(zipcodes);

      return unqiueZipcodes.size === zipcodes.length;
    }),
});

const COUNTRY_CODE = 'US';

type CreateRegionDialogProps = {
  open: boolean,
  onSubmit: (newRegion: Region) => void,
  onClose: () => void,
  onCopyZIPCodes: (zipcodes: string[]) => void,
}

type RegionFormValue = Omit<
  Region, 
  'regionId' | 'zipcodes' | 'createdBy' | 'createdDate'
> & { zipcodes: string };

export const CreateRegionDialog: React.FC<CreateRegionDialogProps> = ({
  open = false,
  onSubmit,
  onClose,
  onCopyZIPCodes,
}) => {
  const { userName } = useSelector((state: { loginToken: { userName: string } }) => state['loginToken']);
  const createRegionMutation = useMutation({ mutationFn: createRegion });
  const formik = useFormik<RegionFormValue>({
    initialValues: {
      regionName: '',
      countryCode: COUNTRY_CODE,
      stateCode: '',
      counties: [],
      cities: [],
      zipcodes: '',
    },
    validationSchema: createRegionValidationSchema,
    validateOnChange: true,
    onSubmit: async ({ zipcodes, ...region }, { setFieldError, resetForm }) => {
      try {
        const response = await createRegionMutation.mutateAsync({
          ...region,
          zipcodes: zipcodesToArray(zipcodes),
          createdBy: userName,
        });

        const parsedCities = response.cities === null
          ? []
          : Array.isArray(response.cities) ? response.cities : [response.cities];

        const parsedCounties = response.counties === null
          ? []
          : Array.isArray(response.counties) ? response.counties : [response.counties];

        const parsedZipcodes = response.zipcodes ? zipcodesToArray(response.zipcodes) : [];

        const createdRegion = {
          ...response,
          cities: parsedCities,
          counties: parsedCounties,
          zipcodes: parsedZipcodes,
        };

        onSubmit(createdRegion);
        resetForm();
      } catch (err) {
        if (!axios.isAxiosError(err)) throw new Error(String(err));

        const { statusCode, message: errMessage } = err.response?.data;
        if (statusCode === 'CONFLICT') {
          setFieldError('regionName', errMessage);
          
          return;
        }

        if (statusCode === 'BAD_REQUEST' && errMessage === GENERIC_CONSTANTS.ZIPCODES_NOT_FOUND) {
          setFieldError('zipcodes', errMessage);
          return;
        }
      }
    },
  });

  const states = useStates(COUNTRY_CODE);
  const counties = useCounties(COUNTRY_CODE, formik.values.stateCode);
  const cities = useCities(COUNTRY_CODE, formik.values.stateCode);

  const handleClose = () => {
    formik.resetForm();
    onClose?.();
  };

  const handleCopyZIPCodes = () => onCopyZIPCodes(zipcodesToArray(formik.values.zipcodes));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: formik.handleSubmit,
      }}
    >
      <DialogTitle>Create new Region</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '500px' }}>
        <FormControl>
          <FormLabel required>{GENERIC_CONSTANTS.REGION_NAME}</FormLabel>
          <TextField
            {...formik.getFieldProps('regionName')}
          />
          <FormHelperText error sx={{ minHeight: '20px', marginLeft: 0 }}>
            {formik.touched.regionName && formik.errors.regionName}
          </FormHelperText>
        </FormControl>

        <FormControl sx={{ marginBottom: '20px' }}>
          <FormLabel required>{GENERIC_CONSTANTS.COUNTRY}</FormLabel>
          <Select {...formik.getFieldProps('countryCode')} readOnly>
            <MenuItem value="US">US</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel required>{GENERIC_CONSTANTS.STATE}</FormLabel>
          <Select {...formik.getFieldProps('stateCode')}>
            {states?.map((state) => <MenuItem value={state} key={state}>{state}</MenuItem>)}
          </Select>
          <FormHelperText error sx={{ minHeight: '20px', marginLeft: 0 }}>
            {formik.touched.stateCode && formik.errors.stateCode}
          </FormHelperText>
        </FormControl>

        <FormControl sx={{ marginBottom: '20px' }}>
          <FormLabel>{GENERIC_CONSTANTS.COUNTIES}</FormLabel>
          <MultiSelect
            id="counties"
            name="counties"
            options={counties ?? []}
            value={formik.values.counties}
            onChange={(values) => formik.setFieldValue('counties', values)}
          />
        </FormControl>

        <FormControl sx={{ marginBottom: '20px' }}>
          <FormLabel>{GENERIC_CONSTANTS.CITIES}</FormLabel>
          <MultiSelect
            id="cities"
            name="cities"
            options={cities ?? []}
            value={formik.values.cities}
            onChange={(values) => formik.setFieldValue('cities', values)}
          />
        </FormControl>

        <TextareaWithActions
          value={formik.values.zipcodes}
          onChange={formik.handleChange('zipcodes')}
          errors={formik.errors.zipcodes ? [formik.errors.zipcodes] : undefined}
          label={GENERIC_CONSTANTS.ZIP_CODES}
          actions={!formik.errors.zipcodes && (
            <Tooltip title={GENERIC_CONSTANTS.COPY_ALL_ZIP_CODES} placement="top-start">
              <IconButton
                sx={{ padding: 0 }}
                onClick={handleCopyZIPCodes}
              >
                <CopyAllIcon />
              </IconButton>
            </Tooltip>
          )}
        />
      </DialogContent>

      <DialogActions sx={{ padding: 3 }}>
        <Button
          variant="text"
          onClick={handleClose}
        >
            Cancel
        </Button>
        <Button
          variant="contained"
          className={formik.isValid ? 'continue-btn' : 'continue-btn-disabled'}
          disabled={!formik.isValid}
          type="submit"
        >
          {GENERIC_CONSTANTS.SAVE_AND_AUTOFILL}
        </Button>
      </DialogActions>
    </Dialog>
  )
}