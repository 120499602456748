const env = window.__ENV__?.REACT_APP_ENV || process.env.REACT_APP_ENV;

const getApiHeaders = (accessToken: any) => ({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
    "X-Auth-Token": env === 'prod' || env === 'stress' ? accessToken : null
  }
});

export default getApiHeaders;
