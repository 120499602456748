import api from "./api";

type FetchStatesFunc = (countryCode: string) => Promise<string[]>;
export const fetchStates: FetchStatesFunc = async (countryCode) => {
  if (!countryCode) {
    throw new Error('No countryCode were provided!');
  }

  const params = new URLSearchParams({ 'country-code': countryCode });
  const response = await api.get(`/proxy/v1/geolocation?${params.toString()}`);

  return response?.data;
};

type FetchCountiesFunc = (countryCode: string, stateCode: string) => Promise<string[]>;
export const fetchCounties: FetchCountiesFunc = async (countryCode, stateCode) => {
  if (!countryCode || !stateCode) {
    throw new Error('No countryCode or stateCode were provided!');
  }

  const params = new URLSearchParams({
    'country-code': countryCode,
    'state-code': stateCode,
    county: 'true',
  });
  const response = await api.get(`/proxy/v1/geolocation?${params.toString()}`);

  return response?.data;
};

type FetchCitiesFunc = (countryCode: string, stateCode: string) => Promise<string[]>;
export const fetchCities: FetchCitiesFunc = async (countryCode, stateCode) => {
  if (!countryCode || !stateCode) {
    throw new Error('No countryCode or stateCode were provided!');
  }

  const params = new URLSearchParams({
    'country-code': countryCode,
    'state-code': stateCode,
    city: 'true',
  });
  const response = await api.get(`/proxy/v1/geolocation?${params.toString()}`);

  return response.data;
};

export type Region = {
  regionId: number,
  regionName: string,
  countryCode: string,
  stateCode: string,
  createdBy: string,
  createdDate: string,
  counties?: string[],
  cities?: string[],
  zipcodes?: string[],
};

type NewRegionValues = Omit<Region, 'regionId' | 'createdDate'>;
type NewRegionResponse = Omit<Region, 'zipcodes' | 'cities' | 'counties'>
  & { zipcodes: string, cities: string | string[], counties: string | string[] };
type CreateRegionFunc = (region: NewRegionValues) => Promise<NewRegionResponse>;
export const createRegion: CreateRegionFunc = async (region) => {
  if (!region) {
    throw new Error('Payload should be provided!');
  }

  const response = await api.post('/proxy/v1/regions', region);

  return response.data;
};

export const fetchRegions = async () : Promise<Region[]> => {
  const response = await api.get('/proxy/v1/regions');

  return response.data;
};
