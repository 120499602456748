import { string } from "prop-types";
import Header from "../Header";
import "./NoAccessPage.module.css";

const getNoAccessMessage = (noAccessReason: string) => {
  switch (noAccessReason) {
    case "Pending":
      return (
        <div className="sign_in_Btn_postion">
          <h1 className="display_msg">Signing you on...</h1>
        </div>
      );
    case "Error":
      return (
        <div className="sign_in_Btn_postion">
          <h1 className="display_msg">
            You failed to login. Please try again ...
          </h1>
        </div>
      );
    case "Logout":
      return (
        <div className="sign_in_Btn_postion">
          <h1 className="display_msg">You successfully logged out </h1>
        </div>
      );
    case "Denied":
    default:
      return (
        <div className="sign_in_Btn_postion">
          <h1 className="display_msg">No Access</h1>
        </div>
      );
  }
};

const NoAccessPage = ({ noAccessReason }: { noAccessReason: string }) => (
  <div className="parent_grid_container">
    <Header />
    <div className="grid_container">
      <div>{getNoAccessMessage(noAccessReason)}</div>
    </div>
  </div>
);

NoAccessPage.propTypes = {
  noAccessReason: string
};

export default NoAccessPage;
