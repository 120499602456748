import { useQuery } from '@tanstack/react-query';
import { fetchStates } from 'api/locations';

/**
 * Hook to fetch and cache a list of states.
 * 
 * @param countryCode
 */
export const useStates = (countryCode: string): string[] | null => {
  const { data } = useQuery({
    queryKey: ['geolocation', 'state'],
    queryFn: () => fetchStates(countryCode),
    staleTime: 1_000 * 60 * 60 * 24,
  });

  return data ?? null;
};
