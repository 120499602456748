/* eslint-disable no-underscore-dangle */
const backendUrl = window.__ENV__?.REACT_APP_DNS_URL || process.env.REACT_APP_PING_REDIRECT_URL;
const gcpEnv = window.__ENV__?.REACT_APP_ENV || process.env.REACT_APP_ENV;
/* eslint-enable no-underscore-dangle */
const baseUrl = gcpEnv === "prod" ? "https://pingfederate.prod-genpt.us2.ping.cloud" : "https://pingfederate.stage-genpt.us2.ping.cloud";

const getPingConfig = () => {
  const currentUrl = window.location.href;

  return {
    environmentId: gcpEnv === "prod" ? "d7a48707-7aff-411c-9da1-91490ca4c003" : "18905651-be30-4313-a398-3f24076969c3",
    responseType: "code",
    scope: "openid profile email phone groups",
    redirectUri: backendUrl ?? "",
    logoutRedirectUri: gcpEnv === "local" ? backendUrl : currentUrl,
    baseUrl: baseUrl,
    authUrl: baseUrl + "/as/authorization.oauth2",
    endSessionUrl: baseUrl + "/idp/startSLO.ping",
    userInfoUrl: baseUrl + "/idp/userinfo.openid"
  }
};

export default getPingConfig;
