import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import { memo } from 'react';
import GENERIC_CONSTANTS from '../../../constants/Regulations/Generic';

const DeletePartsConfirmationDialog = memo(({
  title,
  content,
  onConfirm,
  onCancel,
}) => (
  <Dialog
    open
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
      { title }
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" className="alert-dialog-description">
        { content }
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* eslint-disable-next-line max-len */}
      <Button className="dialog-button-primary" variant="contained" onClick={onConfirm}>{ GENERIC_CONSTANTS.DELETE }</Button>
      <Button className="dialog-button-secondary" variant="contained" onClick={onCancel} autoFocus>
        { GENERIC_CONSTANTS.CANCEL }
      </Button>
    </DialogActions>
  </Dialog>
));

DeletePartsConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeletePartsConfirmationDialog;
