import { saveAs } from 'file-saver';
import ExcelFileGeneratorWorker from 'worker-loader!./excel-file-generator.worker.js';

/**
 * Downloads regulation data as an Excel file.
 *
 * @param {Array<Object>|Object} regulationData - The data to include in the Excel file.
 * @param {string} fileName - The name of the Excel file to download.
 *
 * @returns {void}
 *
 * @description
 * Converts the given regulation data (object or array) into an Excel file with formatted
 * headers and columns, then triggers the file download.
 *
 * @example
 * handleFileDownload(regulationData, "Regulations");
 */
export const handleFileDownload = async (regulationData, fileName) => new Promise((resolve, reject) => {
  const worker = new ExcelFileGeneratorWorker();

  worker.onmessage = (event) => {
    if (event.data.success) {
      saveAs(event.data.fileBlob, `${fileName}.xlsx`);
      resolve();
      return;
    }
    reject();
  };

  worker.onerror = () => reject();
  worker.postMessage({ regulationData, fileName });
});

/**
 * Generates a regulation file name based on various filters and options.
 *
 * @param {Object} options - Configuration options for the file name.
 * @param {boolean} [options.isList=false] - Indicates if the file name should include a
 * list prefix.
 * @param {string} [options.stateFilter='ALL'] - The state filter to include in the file name.
 * @param {string} [options.regulationFilter] - The regulation filter to include. Defaults to
 * 'ALL_Regulations' if not provided.
 * @param {string} [options.partsFilter] - The parts filter to include. Defaults to 'All_Parts'
 * if not provided.
 * @returns {string} The generated regulation file name.
 *
 * @example
 * const fileName = generateRegulationFileName({
 *   isList: true,
 *   stateFilter: 'CA',
 *   regulationFilter: 'Reg123',
 *   partsFilter: 'Part456'
 * });
 * console.log(fileName);
 * // Output: "Regulation_List_Reg123_Parts_Part456_State_CA"
 */
export const generateRegulationFileName = ({
  isList = false,
  stateFilter,
  regulationFilter,
  partsFilter,
}) => {
  const regulationPrefix = isList ? 'List_' : '';
  const regulationFilterText = regulationFilter ?? 'ALL_Regulations';
  const partsFilterText = partsFilter ? `Parts_${partsFilter}` : 'All_Parts';

  const isStateFilterEmptyOrAll = !stateFilter || stateFilter === 'All';
  const stateFilterText = isStateFilterEmptyOrAll
    ? ''
    : `_State_${stateFilter}`;

  return `Regulation_${regulationPrefix}${regulationFilterText}_${partsFilterText}${stateFilterText}`;
};
