import dayjs from 'dayjs';

export const getChunkIds = (itemIds, chunkSize, index) => {
  const chunk = itemIds.slice(index, index + chunkSize);
  return Array.from(new Set(chunk));
};

export const getFormattedDate = (dateVal) => {
  if (dayjs && typeof dayjs === 'function') {
    return dayjs(dateVal).format('YYYY-MM-DD');
  }
  return dateVal;
};

export const mergeAndHandleRegulationParts = (
  existingParts = [],
  uploadedParts = [],
) => {
  if (!existingParts?.length && !uploadedParts?.length) {
    return [];
  }

  const uniqueParts = new Map();
  existingParts.forEach((existingPart) => {
    uniqueParts.set(existingPart.id, existingPart);
  });

  uploadedParts.forEach((each) => {
    const formattedPart = {
      ...each,
      id: `${each.lineAbbrev}-${each.partNo}`,
      obsoleteDate: each.obsoleteDate
        ? getFormattedDate(each.obsoleteDate)
        : null,
      expiredDate: each.expiredDate ? getFormattedDate(each.expiredDate) : null,
      bestClass: each.bestClass || null,
      isActive: each.isActive ?? true,
      deletionMark: each.deletionMark || null,
    };
    uniqueParts.set(formattedPart.id, {
      ...uniqueParts.get(formattedPart.id),
      ...formattedPart,
    });
  });

  return Array.from(uniqueParts.values());
};

export const getChunkParts = (parts, itemIds, chunkSize, index) => {
  const chunkIds = getChunkIds(itemIds, chunkSize, index);
  return parts.filter((part) => {
    const partId = part.lineAbbrev.length === 2
      ? `${part.lineAbbrev}_${part.partNo}`
      : part.lineAbbrev + part.partNo;
    return chunkIds.includes(partId);
  });
};

export const createChunkQuery = (itemIds, chunkSize, index) => {
  const chunkIds = getChunkIds(itemIds, chunkSize, index);
  return `query {
    itemLookUp(input: {
      itemIds: [${chunkIds.map((id) => `"${id}"`).join(', ')}]
      filterCondition: { country: US, lang: EN, isFieldLine: true }
    }) {
      itemErrors { message, errorCode, itemId }
      items { title, lineAbbreviation, partNumber, pid, hqAbbreviation }
    }
  }`;
};
