import { useQuery } from "@tanstack/react-query";
import { fetchCities } from "api/locations";

export const useCities = (countryCode: string, stateCode: string): string[] | null => {
  const { data } = useQuery({
    queryKey: ['geolocation', 'cities', countryCode, stateCode],
    queryFn: () => fetchCities(countryCode, stateCode),
    staleTime: 1_000 * 60 * 60 * 24,
    enabled: Boolean(countryCode) && Boolean(stateCode),
  });

  return data ?? null;
}
