import PropTypes from 'prop-types';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

/**
 * Custom Snackbar Component.
 *
 * This Snackbar should not be directly used! Use `useSnackbar` to control its instance.
 *
 * @param {Object} props.snackbarState - State for the Snackbar.
 * @param {boolean} props.snackbarState.open - Determines if the Snackbar is visible.
 * @param {string} props.snackbarState.message - The message to display in the Snackbar.
 * @param {'success' | 'error'} props.snackbarState.severity - The severity of the Snackbar.
 * @param {Function} [props.onClose] - Callback function to handle closing the Snackbar.
 */
const Snackbar = ({
  snackbarState,
  onClose,
}) => {
  const { open, message, severity } = snackbarState;

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <MuiAlert onClose={onClose} severity={severity} variant="filled" elevation={6}>
        {message}
      </MuiAlert>
      {/* TODO: Should we add LinearProgress to this Snackbar? */}
    </MuiSnackbar>
  );
};

Snackbar.propTypes = {
  snackbarState: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['success', 'error']).isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

Snackbar.defaultProps = {
  onClose: () => {},
};

export default Snackbar;
