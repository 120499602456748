import { createSlice } from '@reduxjs/toolkit';
import REGULATIONS_CONSTANTS from '../../constants/Regulations/Regulations';

export const initialState = {
  regulationParts: [],
  uploadPartsError: {},
  regulationFormData: {
    regId: null,
    regTitle: '',
    regDesc: '',
    effDate: '',
    regType: 'Banned',
    ruleNo: '',
    jurisdictionLevel: 'State',
    regionId: null,
    regDist: '',
    countryCode: 'US',
    stateCode: 'CA',
    county: '',
    counties: [],
    city: '',
    cities: [],
    other: null,
    zipCode: '',
    createdBy: '',
    parts: [],
  },
  editRegulationFormData: {},
  newRegulationData: {},
  updatedRegulationData: {},
  regulationList: [],
};

const DATA_FIELDS = [
  REGULATIONS_CONSTANTS.PART_ABBR,
  REGULATIONS_CONSTANTS.PART_NO,
  REGULATIONS_CONSTANTS.PART_DESC,
  REGULATIONS_CONSTANTS.BEST_CLASS,
  REGULATIONS_CONSTANTS.OBSOLETE_DATE,
  REGULATIONS_CONSTANTS.ALT_LINE_ABBR,
  REGULATIONS_CONSTANTS.ALT_PART_NO,
  REGULATIONS_CONSTANTS.ALT_PART_DESC,
  REGULATIONS_CONSTANTS.EXPIRED_DATE,
  REGULATIONS_CONSTANTS.DELETION_MARK,
];

const HEADER_LINE = JSON.stringify([
  REGULATIONS_CONSTANTS.TAMS_LINE_ABBRV,
  REGULATIONS_CONSTANTS.PART_NUMBER,
  REGULATIONS_CONSTANTS.PART_DESCRIPTION,
  REGULATIONS_CONSTANTS.CLASS_CODE,
  REGULATIONS_CONSTANTS.PART_OBSOLETE_DATE,
  REGULATIONS_CONSTANTS.ALT_FIELD_LINE_ABBREV,
  REGULATIONS_CONSTANTS.ALT_PART_NUMBER,
  REGULATIONS_CONSTANTS.ALT_PART_DESCRIPTION,
  REGULATIONS_CONSTANTS.PART_EXPIRED_DATE,
  REGULATIONS_CONSTANTS.MARK_FOR_DELETION,
]);

const covertToPartList = (data) => {
  let partList = [];
  let errMsg = '';
  try {
    data.forEach((v0) => {
      // consider the line is empty/comment if the Line abbreviation is missing
      // validate required fields here: #0 line abbrv and #1 part number is required
      if (v0 && v0.length > 0) {
        if (v0[0] && v0[1]) {
          partList.push(
            v0.reduce((c, v, i) => ({ ...c, [DATA_FIELDS[i]]: v }), {
              [DATA_FIELDS[0]]: v0[0] || '',
            }),
          );
        } else {
          const error = !v0[0]
            ? REGULATIONS_CONSTANTS.MISSING_PART_LINE_ABBR
            : REGULATIONS_CONSTANTS.MISSING_PART_NO;
          throw new Error(error);
        }
      }
    });
  } catch (error) {
    errMsg = `${error.message} in the uploaded template! Please correct the file and upload again.`;
    partList = [];
  }
  return [partList, errMsg];
};

const regulationMaintenanceSlice = createSlice({
  name: 'regulationMaintenanceForm',
  initialState,
  reducers: {
    clearRegulationMaintenanceForm: () => ({
      ...initialState,
    }),

    logError: (state, action) => ({
      ...state,
      uploadPartsError: {
        ...state.uploadPartsError,
        [action.payload.name]: action.payload.value,
      },
    }),

    setPartList: (state, action) => {
      const { tab1Data, tab2Data } = action.payload.value;

      let err;
      let partList = [];

      if (tab1Data?.length > 0 && JSON.stringify(tab1Data[0]) === HEADER_LINE) {
        [partList, err] = covertToPartList(tab1Data.slice(1));
      } else if (tab2Data?.length > 0 && JSON.stringify(tab2Data[0]) === HEADER_LINE) {
        [partList, err] = covertToPartList(tab2Data.slice(1));
      } else {
        err = REGULATIONS_CONSTANTS.WRONG_TEMP;
      }

      return {
        ...state,
        regulationParts: partList,
        uploadPartsError: {
          ...state.uploadPartsError,
          regulationParts: err,
        },
      };
    },
    saveRegulationFormData: (state, action) => ({
      ...state,
      regulationFormData: action.payload.value,
    }),
    updateRegulationFormData: (state, action) => ({
      ...state,
      editRegulationFormData: action.payload.value,
    }),
    clearRegulationFormData: (state) => ({
      ...state,
      regulationFormData: initialState.regulationFormData,
      editRegulationFormData: initialState.editRegulationFormData,
      regulationParts: initialState.regulationParts,
      uploadPartsError: initialState.uploadPartsError,
      updatedRegulationData: initialState.updatedRegulationData,
    }),
    setAllRegulationList: (state, action) => ({
      ...state,
      regulationList: action.payload.value,
    }),
    setNewRegulationData: (state, action) => ({
      ...state,
      newRegulationData: action.payload.value,
    }),
    setPartListToUpdate: (state, action) => ({
      ...state,
      regulationParts: action.payload.value,
    }),
    setUpdatedRegulationData: (state, action) => ({
      ...state,
      updatedRegulationData: action.payload.value,
    }),
  },
});

export const {
  saveRegulationFormData,
  updateRegulationFormData,
  setAllRegulationList,
  setPartList,
  setNewRegulationData,
  setPartListToUpdate,
  setUpdatedRegulationData,
  clearRegulationMaintenanceForm,
  clearRegulationFormData,
  logError,
} = regulationMaintenanceSlice.actions;

export default regulationMaintenanceSlice.reducer;
