import axios from "axios";
import getApiUrls from "./utils/apiUrls";

const backendUrl = getApiUrls();

const REGULATIONS_API_BASE = axios.create({
  baseURL: backendUrl
});

export default REGULATIONS_API_BASE;
