import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Snackbar from './Snackbar';
import SnackbarContext from './snackbar-context';

/**
 * SnackbarProvider to manage Snackbar state globally.
 */
const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const showSnackbar = useCallback((message, severity = 'success') => {
    setSnackbarState({ open: true, message, severity });
  }, []);

  const showSuccessSnackbar = useCallback(
    (message) => showSnackbar(message, 'success'),
    [showSnackbar],
  );

  const showErrorSnackbar = useCallback(
    (message) => showSnackbar(message, 'error'),
    [showSnackbar],
  );

  const handleClose = useCallback(() => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  }, []);

  const contextValue = useMemo(
    () => ({ showSuccessSnackbar, showErrorSnackbar }),
    [showSuccessSnackbar, showErrorSnackbar],
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar snackbarState={snackbarState} onClose={handleClose} />
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SnackbarProvider;
