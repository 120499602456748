const REGULATIONS_CONSTANTS = {
  DOWNLOAD_SEARCH_RESULTS_TITLE: 'Download search results',
  DOWNLOAD_PARTS_FOR_SELECTED_REGULATION:
    'Download parts for selected regulation',
  REGULATORY_INFO: 'Regulatory Information',
  REGULATIONS: 'Regulations',
  REGULATED_PARTS: 'Regulated Parts',
  REGULATION: 'Regulation',
  STATE: 'State',
  RULE: 'Rule',
  JURISDICTION_LEVEL: 'Jurisdiction Level',
  CREATE_NEW_REGION: 'Create new Region',
  REGION: 'Region',
  CODE: 'Code',
  JURISDICTION: 'Jurisdiction',
  HQ_LINE: 'HQ Line',
  FIELD_LINE: 'Field Line',
  PART_NUMBER: 'Part Number',
  PART_DESCRIPTION: 'Part Description',
  VIEW_REGULATIONS: 'View Regulations',
  ADD_REGULATION: 'Add Regulation',
  UPDATE_REGULATION: 'Update Regulation',
  ADD_NEW_REGULATION: '+ Add New Regulation',
  REGULATION_HEADER_LABELS: [
    'Regulation Title',
    'Rule #',
    'Jurisdiction',
    'State',
    'Restriction',
    'Eff. Date',
    'Total Parts',
    'Edit',
    'Effective Date',
    'Delete',
  ],
  REG_TITLE: 'regTitle',
  REG_DESC: 'regDesc',
  RULE_NO: 'ruleNo',
  REG_JURISDICTION_LEVEL: 'jurisdictionLevel',
  STATE_CODE: 'stateCode',
  REG_TYPE: 'regType',
  EFF_DATE: 'effDate',
  PARTS: 'parts',
  REG_CITY: 'city',
  REG_DIST: 'regDist',
  REG_COUNTY: 'county',
  REG_COUNTRY: 'countryCode',
  PART_ABBR: 'lineAbbrev',
  PART_NO: 'partNo',
  PART_DESC: 'partDesc',
  CREATED_DATE: 'createdDate',
  CREATED_BY: 'createdBy',
  LAST_MODIFIED: 'Last Modified',
  MODIFIED_BY: 'modifiedBy',
  OBSOLETE_DATE: 'obsoleteDate',
  ALT_LINE_ABBR: 'altLineAbbrev',
  ALT_PART_NO: 'altPartNumber',
  ALT_PART_DESC: 'altPartDesc',
  MODIFIED_DATE: 'modifiedDate',
  OTHER: 'other',
  EXPIRED_DATE: 'expiredDate',
  BEST_CLASS: 'bestClass',
  DELETION_MARK: 'deletionMark',
  DELETION_MARK_VALUE: 'Y',
  REGULATION_DETAILS_HEADER_LABELS: [
    'Regulation Description',
    'Effective Date',
    'State',
    'County',
    'City',
    'Region',
    'Created Date',
    'Created By',
    'Modified Date',
    'Modified By',
    'Country',
    'County',
    'City',
  ],
  REGULATION_PARTS_LABELS: [
    'Field Line Abbrev',
    'Part Number',
    'Part Description',
    'Obsolete Date',
    'Alt. Field Line Abbrev',
    'Alt. Part Number',
    'Alt. Part Description',
    'Created Date',
    'Created By',
    'Modified Date',
    'Modified By',
    'Class Code',
    'Part Expiration',
  ],
  DETAILS_TITLE: 'Regulation Parts',
  ADDITIONAL_DETAILS_TITLE: 'Additional Details',
  API_FAILURE: 'Failed to receive regulations. Please try again later.',
  NO_SEARCH_RESULTS: 'Sorry, no results found matching the filter criteria.',
  NO_SEARCH_PARTS_RESULTS:
    'Sorry, no results found matching the search criteria.',
  NO_PARTS_AVAILABLE: 'No parts available',
  SEARCH_PLACEHOLDER: 'Search by Field Line/Part Number/Part Description',
  SEARCH_FIELDLINE_PART_PLACEHOLDER: 'Search by Field Line/Part Number',
  STEPPER_TITLES: ['Primary details', 'Jurisdiction', 'Regulated Parts'],
  STEPPER_SUB_TITLES: [
    'Please enter the primary details to create a new regulation',
    'Please select the jurisdiction associated to the new regulation',
    'Use Excel File to upload the parts associated to the new regulation',
  ],
  STEPPER_TITLES_EDIT: ['Regulation details', 'Regulated Parts'],
  STEPPER_SUB_TITLES_EDIT: [
    'Editable fields are enabled to update',
    'Please search the parts that needs an update',
  ],
  RES_CODE: 'Restriction Code',
  BANNED: 'Banned',
  RESTRICTED: 'Restricted',
  REG_LEVEL_OPTIONS: [
    { label: 'Country-wide', value: 'Country' },
    { label: 'State-wide', value: 'State' },
    { label: 'County-wide', value: 'County' },
    { label: 'City-wide', value: 'City' },
    { label: 'Region-wide', value: 'Region' },
  ],
  FORM_VALIDITY_ERROR: 'Please fill out the required fields',
  UPLOAD_PARTS_ERROR:
    'Please upload the parts in order to sumbit the regulation',
  REGULATION_RULE: 'Regulation Rule',
  SAVE_API_SUCCESS: ' created succesfully!',
  SAVE_API_FAILURE: 'Failed to add regulation. Please try again later.',
  GEO_LOCATION_API_FAILURE:
    'Failed to receive geo location data. Please try again later.',
  DATA_DISCARD_ALERT_TITLE: 'Regulation data not submitted',
  DATA_DISCARD_ALERT_MSG:
    'You have unsaved data in the form that will be lost if not submitted. Are you sure you want to leave the page?',
  UNABLE_READ_FILE: 'Unable to read file',
  MISSING_PART_NO: 'Missing part number',
  MISSING_PART_LINE_ABBR: 'Missing field line abbreviation',
  WRONG_TEMP: 'Wrong template',
  UPDATE_API_SUCCESS: ' updated succesfully!',
  REGULATION_DELETED_SUCCESSFULLY: (regulationTitle) => `${regulationTitle} deleted successfully!`,
  REGULATION_DELETE_FAILED: 'Failed to delete regulation. Please try again later.',
  REGULATION_NOT_FOUND: (regulationId) => `Regulation with id: ${regulationId} not found.`,
  EXCEL_DOWNLOAD_FAILURE: 'Failed to download search results. Please try again later.',
  UPDATE_API_FAILURE: 'Failed to update regulation. Please try again later.',
  DELETE_PARTS_API_FAILURE: 'Failed to delete selected parts. Please try again.',
  DELETE_PARTS_API_SUCCESS: 'Selected parts were successfully deleted.',
  REG_CLASS_CODES: [
    'A',
    'B',
    'C',
    'D',
    'NL',
    'NP',
    'NR',
    'NX',
    'O',
    'S',
    'SD',
    'TA',
    'TB',
    'TC',
    'TD',
    'TW',
    'W',
    'WW',
    'XA',
    'XB',
    'XC',
    'XD',
    'Z',
  ],
  // labels for xlsx export
  REGULATION_TITLE: 'Regulation Title',
  REGULATION_DESCRIPTION: 'Regulation Description',
  REGULATION_RULE_NO: 'Regulation Rule #',
  RESTRICTION: 'Restriction',
  REGION_NAME: 'Region Name',
  COUNTRY: 'Country',
  COUNTIES: 'Counties',
  CITIES: 'Cities',
  ZIP_CODES: 'Zip Codes',
  TOTAL_PARTS: 'Total Parts',
  REGULATION_EFF_DATE: 'Regulation Effective Date',
  REGULATION_CREATED_DATE: 'Regulation Created Date',
  REGULATION_CREATED_BY: 'Regulation Created By',
  REGULATION_MODIFIED_DATE: 'Regulation Modified Date',
  REGULATION_MODIFIED_BY: 'Regulation Modified By',
  TAMS_LINE_ABBRV: 'TAMS Line Abbrv',
  PART_EXPIRED_DATE: 'Expired Date',
  PART_OBSOLETE_DATE: 'Obsolete Date',
  CLASS_CODE: 'Class Code',
  ALT_FIELD_LINE_ABBREV: 'Alt. Field Line Abbrev',
  ALT_PART_NUMBER: 'Alt. Part Number',
  ALT_PART_DESCRIPTION: 'Alt. Part Description',
  PART_CREATED_DATE: 'Part Created Date',
  PART_CREATED_BY: 'Part Created By',
  PART_MODIFIED_DATE: 'Part Modified Date',
  PART_MODIFIED_BY: 'Part Modified By',
  MARK_FOR_DELETION: 'Mark For Deletion',
  EDIT: 'Edit',
  DELETE: 'DELETE',
  SHOW_SELECTED_FIRST: 'Show selected first',
};

export default REGULATIONS_CONSTANTS;
