import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { string } from "prop-types";
import authClient from "./pingApi";
import getPingConfig from "./utils/pingConfig";
import {
  setAuthxToken,
  logError,
  setAuthxStatus,
  clearLoginToken
} from "@redux/loginTokenSlice";
import { setUserInfo } from "@redux/loginTokenSlice/loginTokenSlice";
import {
  AUTHX_ALLOWED,
  AUTHX_ERROR,
  AUTHX_LOGGGED_OUT,
  AUTHX_PENDING
} from "./utils/AuthxConstants";
import { type ReduxStore } from "@redux/store";

// eslint-disable-next-line import/prefer-default-export
// TODO: avoid using more then 2 arguments in function arguments
export const authxTokenCheck = (code: string, err: string, ipath: string) => {
  const { authxStatus, authxToken } = useSelector(
    (state: ReduxStore) => state.loginToken
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logErr = (levelStr: string, msg: string) =>
    dispatch(logError(`${levelStr}: ${msg}`));

  const startPingLogin = () => {
    dispatch(clearLoginToken(null));
    authClient.authorize();
  };

  const needNewAccessToken = () => {
    let wrkAuthxToken = authxToken;
    if (!authxToken) {
      const sessAuthxToken = JSON.parse(
        sessionStorage.getItem("authxToken") as string
      );
      wrkAuthxToken = sessAuthxToken;
      const sessUserInfo = JSON.parse(
        sessionStorage.getItem("userInfo") as string
      );
      if (!sessAuthxToken || !sessUserInfo) {
        startPingLogin();
      }

      if (!authxToken && wrkAuthxToken) {
        dispatch(setAuthxToken(wrkAuthxToken));
        dispatch(setUserInfo(sessUserInfo));
        dispatch(setAuthxStatus(AUTHX_ALLOWED));
      }
    }

    if (wrkAuthxToken) {
      if (authClient.isTokenExpired(wrkAuthxToken["access_token"])) {
        // if (authClient.isTokenExpired(authxToken.id_token)) {
        // TODO find out how to refresh token
        startPingLogin();
        // }
      }
    } else {
      startPingLogin();
    }
  };

  const handleUserInfo = (pingToken: { access_token: string }) => {
    authClient
      .getUserInfo(pingToken.access_token)
      .then((result) => {
        sessionStorage.setItem("userInfo", JSON.stringify(result.data));
        sessionStorage.setItem("authxToken", JSON.stringify(pingToken));
        dispatch(setUserInfo(result.data));
        dispatch(setAuthxToken(pingToken));
        dispatch(setAuthxStatus(AUTHX_ALLOWED));
        navigate("/landing");
      })
      .catch((error) => {
        dispatch(setAuthxStatus(AUTHX_ERROR));
        dispatch(setUserInfo(null));
        const errDtl = _.get(error, "details[0].code", null);
        const errMsg = _.get(error, "details[0].message", null);
        if (_.isEqual(errDtl, "INVALID_VALUE")) {
          if (errMsg.includes("Access token expired")) {
            logErr("", "Your access token is expired. Please login again.");
          } else {
            logErr("", errMsg);
          }
        } else if (errDtl) {
          logErr("", `errorDetail ${errMsg}`);
        } else {
          const wrkErr = _.get(error, "error", null);
          const wrkErrDesc = _.get(error, "error_description", null);
          if (wrkErr || wrkErrDesc) {
            logErr("", `${wrkErr}: ${wrkErrDesc}`);
          }
        }
      });
  };

  useEffect(() => {
    if (authxStatus !== "Error" && ipath !== "/logout") {
      if (err) {
        logErr("", err);
        dispatch(setAuthxStatus(AUTHX_ERROR));
      }
      // skip the attempt to retrieve token when:
      // authxStatus !== AUTHX_PENDING -- the as token call is in progress
      // authxStatus !== AUTHX_ERROR -- the as token/get userinfo call has failed
      // authxStatus !== AUTHX_ALLOWED -- the login is good. no more trail
      if (
        code &&
        ipath === "/" &&
        authxStatus !== AUTHX_ERROR &&
        authxStatus !== AUTHX_PENDING &&
        authxStatus !== AUTHX_ALLOWED
      ) {
        dispatch(setAuthxStatus(AUTHX_PENDING));

        authClient
          .getAccessToken(code, getPingConfig().redirectUri as string)
          .then((token) => {
            // @ts-ignore
            if (authClient.verifyToken(token.data.access_token)) {
              handleUserInfo(token.data);
            } else {
              dispatch(setAuthxStatus(AUTHX_ERROR));
              logErr("", "invalid access token");
            }
          })
          .catch((error) => {
            dispatch(setAuthxStatus(AUTHX_ERROR));
            logErr(
              "",
              `Couldn't get an access token. ${_.get(
                error,
                "error_description",
                _.get(error, "message", "")
              )}`
            );
            dispatch(setAuthxStatus(AUTHX_ERROR));
          });
      }
    }
    if (ipath === "/logout") {
      dispatch(clearLoginToken(null));
      dispatch(setAuthxStatus(AUTHX_LOGGGED_OUT));
    } else if (!err && !code) {
      needNewAccessToken();
    }
  }, [code, err, authxStatus]);
};

authxTokenCheck.prototype = {
  code: string,
  err: string,
  ipath: string
};
