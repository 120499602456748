const GENERIC_CONSTANTS = {
  SEARCH: 'Search',
  LOGOUT_MESSAGE: 'Are you sure you want to log out?',
  YES: 'Yes',
  CANCEL: 'Cancel',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  LOGIN: 'Login',
  LOGIN_SSO: 'Login with SSO',
  SSO_TOKEN: 'SSO Token',
  COUNTRY: 'Country',
  STATE: 'State',
  COUNTY: 'County',
  COUNTIES: 'Counties',
  CITY: 'City',
  CITIES: 'Cities',
  BACK: 'Back',
  SAVE_CONTINUE: 'Save & Continue',
  CREATE: 'Create',
  DISCARD: 'Discard and Leave',
  UPLOAD: 'Upload',
  DOWNLOAD: 'Download',
  DOWNLOAD_TEMPLATE: 'Download Template',
  UPDATE: 'Update',
  UPDATE_CONTINUE: 'Update & Continue',
  SUBMIT: 'Submit',
  UNSAVED_CHANGES_DETECTED: 'Unsaved changes detected',
  SAVE_UPLOADED_CHANGES_MESSAGE:
    'Are you sure you want to save the uploaded changes?',
  SAVE_AND_DOWNLOAD: 'Save and Download',
  DELETE: 'Delete',
  DELETE_PARTS_TITLE: 'Deleting parts',
  DELETE_PARTS_MESSAGE: (partsNo) => `Are you sure you want to delete selected ${partsNo} part(s)?`,
  DELETE_REGULATION_MESSAGE: 'Are you sure you want to delete this regulation?',
  CONFIRM: 'Confirm',
  ZIP_CODES: 'Zip Codes',
  ALL_ZIP_CODES: 'All Zip Codes',
  NO_ZIP_CODES: 'No Zip Codes',
  SEACRH_ZIP_CODES: 'Search Zip Codes',
  COPY_ALL_ZIP_CODES: 'Copy all Zip Codes',
  SHOW_ALL_ZIP_CODES: 'Show all Zip Codes',
  REGION_NAME: 'Region name',
  REGION_NAME_IS_REQUIRED: 'Region name is required',
  STATE_IS_REQUIRED: 'State is required',
  INVALID_ZIPCODES_FORMAT: 'Invalid ZIP Code format',
  ZIPCODES_MUST_BE_UNIQUE: 'ZIP Codes must be unique',
  ZIPCODES_NOT_FOUND: 'zipcodes not found',
  SAVE_AND_AUTOFILL: 'Save & Autofill',
};

export default GENERIC_CONSTANTS;
