import REGULATIONS_API_BASE from "./axiosApi";
import getApiHeaders from "@services/utils/apiHeaders";

const getRegions = (accessToken: any) => {
    const headers = getApiHeaders(accessToken);
    const config = {
        ...headers
    };
    return REGULATIONS_API_BASE.get('/proxy/v1/regions', config);
}

export default { getRegions };
