import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import GENERIC_CONSTANTS from '@constants/Regulations/Generic';

const DownloadConfirmationDialog = ({
  isDownloadDialogOpen,
  handleDialogClose,
  handleConfirmDownload,
}) => (
  <Dialog
    open={isDownloadDialogOpen}
    onClose={handleDialogClose}
    aria-labelledby="confirm-download-title"
    aria-describedby="confirm-download-description"
  >
    <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
      {GENERIC_CONSTANTS.UNSAVED_CHANGES_DETECTED}
    </DialogTitle>
    <DialogContent>
      <DialogContentText
        id="alert-dialog-description"
        className="alert-dialog-description"
      >
        {GENERIC_CONSTANTS.SAVE_UPLOADED_CHANGES_MESSAGE}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        className="dialog-button-primary"
        variant="contained"
        onClick={handleConfirmDownload}
      >
        {GENERIC_CONSTANTS.SAVE_AND_DOWNLOAD}
      </Button>
      <Button
        className="dialog-button-secondary"
        variant="contained"
        onClick={handleDialogClose}
        autoFocus
      >
        {GENERIC_CONSTANTS.CANCEL}
      </Button>
    </DialogActions>
  </Dialog>
);

DownloadConfirmationDialog.propTypes = {
  isDownloadDialogOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  handleConfirmDownload: PropTypes.func.isRequired,
};

export default DownloadConfirmationDialog;
