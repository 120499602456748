// eslint-disable-next-line no-unused-vars
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as XLSX from 'xlsx-js-style';
import { useDispatch } from 'react-redux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GENERIC_CONSTANTS from '@constants/Regulations/Generic';
import { setPartList, logError } from '@redux/regulationMaintenanceSlice';
import './UploadPartListButton.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadPartListButton = () => {
  const dispatch = useDispatch();

  const parseSheetData = (data = []) => data.map((row, index) => {
    if (index > 0 && row?.[0] && row?.[1]) {
      const fl = row[0].replace(/ /g, '').replace(/[^a-zA-Z0-9]/g, '');
      const part = row[1].replace(/ /g, '').replace(/[^a-zA-Z0-9]/g, '');
      return [fl, part, ...row.slice(2)];
    }
    return row || [];
  });

  const convertSheetToJson = (ws) => XLSX.utils.sheet_to_json(ws, {
    header: 1,
    raw: false,
    dateNF: 'mm-dd-yyyy',
    UTC: true,
  });

  const getSheetData = (wb, tabIndex) => {
    const sheetName = wb.SheetNames?.[tabIndex];
    if (!sheetName) return [];
    const sheet = wb.Sheets[sheetName];
    return parseSheetData(convertSheetToJson(sheet));
  };

  const handleFileInput = (e) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = (evt) => {
          try {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {
              type: 'binary',
              cellDates: true,
              cellNF: false,
              cellText: false,
            });

            const tab1Data = getSheetData(wb, 0);
            const tab2Data = getSheetData(wb, 1);

            dispatch(setPartList({ value: { tab1Data, tab2Data } }));
            dispatch(logError({}));
          } catch (error) {
            dispatch(setPartList({ value: { tab1Data: [], tab2Data: [] } }));
            dispatch(
              logError({
                name: 'regulationParts',
                value: error.message,
              }),
            );
          }
        };
        reader.readAsBinaryString(file);
      } catch (error) {
        dispatch(setPartList({ value: { tab1Data: [], tab2Data: [] } }));
        dispatch(logError({ name: 'regulationParts', value: error.message }));
      }
    }
  };

  const handleFileClick = (e) => {
    if (e.target.value) {
      e.target.value = '';
    }
  };

  return (
    <Tooltip title={GENERIC_CONSTANTS.UPLOAD} placement="top">
      <IconButton
        className="upload-part-list-button"
        id="regulatedPartsFile"
        size="large"
        component="label"
      >
        <CloudUploadIcon />
        <VisuallyHiddenInput
          id="uploadFileInput"
          onClick={handleFileClick}
          onChange={handleFileInput}
          type="file"
        />
      </IconButton>
    </Tooltip>
  );
};

export default UploadPartListButton;
