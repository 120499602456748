import getApiUrls from '@services/utils/apiUrls';
import axios from 'axios';

const api = axios.create({
  baseURL: getApiUrls(),
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
  },
});

const env = window.__ENV__?.REACT_APP_ENV || process.env.REACT_APP_ENV;

api.interceptors.request.use((config) => {
  const storageToken = sessionStorage.getItem('authxToken');
  if (!storageToken) {
    return config;
  }

  const { access_token: accessToken } = JSON.parse(storageToken);
  if (accessToken && (env === 'prod' || env === 'stress')) {
    config.headers['X-Auth-Token'] = accessToken;
  }

  return config;
});

export default api;
