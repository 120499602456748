import { useQuery } from "@tanstack/react-query";
import { fetchCounties } from "api/locations";

/**
 * Hook to fetch and cache counties for a selected state.
 * Note that the countryCode is hardcoded.
 * 
 * @param countryCode - the code the selected country.
 * @param stateCode - The code of the selected state.
 * @returns List of counties for the selected state.
 */
export const useCounties = (countryCode: string, stateCode: string): string[] | null => {
  const { data } = useQuery({
    queryKey: ['geolocation', 'counties', countryCode, stateCode],
    queryFn: () => fetchCounties(countryCode, stateCode),
    staleTime: 1_000 * 60 * 60 * 24,
    enabled: Boolean(countryCode) && Boolean(stateCode),
  });

  return data ?? null;
};
