import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import GENERIC_CONSTANTS from '../../../constants/Regulations/Generic';

const DeleteConfirmationDialog = memo(({
  title,
  onConfirm,
  onCancel,
}) => (
  <Dialog
    open
    onClose={onCancel}
    aria-labelledby="confirm-delete-title"
    aria-describedby="confirm-delete-description"
  >
    <DialogTitle>
      {GENERIC_CONSTANTS.DELETE_REGULATION_MESSAGE}
    </DialogTitle>

    <CenteredContent>
      <DialogContentText
        id="alert-dialog-description"
        className="alert-dialog-description"
      >
        {title}
      </DialogContentText>
    </CenteredContent>

    <DialogActions>
      <Button
        className="dialog-button-primary"
        variant="contained"
        onClick={onConfirm}
      >
        {GENERIC_CONSTANTS.CONFIRM}
      </Button>
      <Button
        className="dialog-button-secondary"
        variant="contained"
        onClick={onCancel}
        autoFocus
      >
        {GENERIC_CONSTANTS.CANCEL}
      </Button>
    </DialogActions>
  </Dialog>
));

const CenteredContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

DeleteConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteConfirmationDialog;
