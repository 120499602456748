/**
 * PingOne OpenID Connect/OAuth 2 protocol API
 */
import { isExpired, decodeToken } from "react-jwt";
import getPingConfig from "./utils/pingConfig";
import getApiUrls from "./utils/apiUrls";
import REGULATIONS_API_BASE from "./axiosApi";

const getBackendUrl = getApiUrls();

function isValidUrl(url: string) {
  // Restrict URLs to HTTP only. This blocks FTP and other protocols
  const validUrlRegex = /^https:\/\/\S+$/;
  return validUrlRegex.test(url);
}

const authorize = () => {
  if (isValidUrl(`${getBackendUrl}/proxyping/getClientId`)) {
    REGULATIONS_API_BASE.get(`${getBackendUrl}/proxyping/getClientId`)
      .then((data) => {
        const { redirectUri, scope, responseType, authUrl } = getPingConfig();
        const params = new URLSearchParams({
          client_id: data.data.clientId,
          redirect_uri: redirectUri,
          scope,
          response_type: responseType,
        });

        window.location.replace(`${authUrl}?${params}`);
      })
      .catch((error) => error);
  }
};

const signOff = (token: string, state: string) => {
    const pingConfig = getPingConfig();
    let signOffUrl = pingConfig.endSessionUrl;

    if (pingConfig.logoutRedirectUri && state) {
        let params = new URLSearchParams({
            TargetResource: pingConfig.logoutRedirectUri,
            state: state
        });
        signOffUrl = `${pingConfig.endSessionUrl}?${params}`;
    }

    window.location.assign(signOffUrl);
};

const getUserInfo = (accessToken: string) =>
  REGULATIONS_API_BASE.get(
    getPingConfig().userInfoUrl,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

const getAccessToken = (code: string, redirectUri: string) => {
    const url = `${getBackendUrl}/proxyping/getAccessToken`;
    const params = new URLSearchParams({ redirectUri, authCode: code });

    return REGULATIONS_API_BASE.get(`${url}?${params}`);
}


const verifyToken = (inToken: string) =>
  REGULATIONS_API_BASE.get(`${getBackendUrl}/proxyping/getClientId`)
    .then((data) => {
      const decodedToken: {
        aud: string[];
        env: string;
        clientId: string;
      } | null = decodeToken(inToken);
      if (!decodedToken) {
        throw new Error("Error decoding token");
      }

      const pingConfig = getPingConfig();
      return (
        decodedToken.aud[0] === pingConfig.baseUrl &&
        decodedToken.env === pingConfig.environmentId &&
        decodedToken.clientId === data.data.clientId
      );
    })
    .catch((error) => error);

const isTokenExpired = (inToken: string) => isExpired(inToken);

const parseHash = () =>
  window.location.hash
    .replace("#", "")
    .split("&")
    .reduce(
      (prev, item) => ({
        [item.split("=")[0]]: decodeURIComponent(item.split("=")[1]),
        ...prev
      }),
      {}
    );

const generateRandomValue = () => {
  const crypto = window.crypto || window.msCrypto;
  const D = new Uint32Array(2);
  crypto.getRandomValues(D);
  return D[0].toString(36);
};

type Formatter = (key: string | number) => string;

export const flatten = (
  objectOrArray: Record<string, any> | any[], // Accepts objects or arrays
  prefix: string = "",
  formatter: Formatter = (k) => k.toString()
): Record<string, any> => {
  const nestedFormatter = (key: string | number) => `${formatter(key)}`;

  const nestElement = (
    prev: Record<string, any>,
    value: any,
    key: string | number
  ): Record<string, any> =>
    value && typeof value === "object"
      ? {
          ...prev,
          ...flatten(value, `${prefix}${formatter(key)}.`, nestedFormatter)
        }
      : {
          ...prev,
          [`${prefix}${formatter(key)}`]: value
        };

  return Array.isArray(objectOrArray)
    ? objectOrArray.reduce(
        (prev, value, index) => nestElement(prev, value, index),
        {}
      )
    : Object.keys(objectOrArray).reduce(
        (prev, key) => nestElement(prev, objectOrArray[key], key),
        {}
      );
};

export const CLAIMS_MAPPING = {
  at_hash: "Access Token hash value.",
  sub: "User Identifier.",
  name: "User's full name.",
  given_name: "User given name(s) or first name(s).",
  family_name: "Surname(s) or last name(s) of the User.",
  middle_name: "User middle name.",
  nickname: "User casual name.",
  preferred_username: "User shorthand name.",
  email: "User e-mail address.",
  updated_at: "Last time User's information was updated.",
  amr: "Authentication Methods Reference.",
  iss: "Response Issuer Identifier.",
  nonce: "Client session unique and random value.",
  aud: "ID Token Audience.",
  acr: "Authentication Context Class Reference.",
  auth_time: "User authentication time.",
  exp: "ID Toke expiration time.",
  iat: "Time at which the JWT was issued.",
  address_country: "Country name. ",
  address_postal_code: "Zip code or postal code. ",
  address_region: "State, province, prefecture, or region. ",
  address_locality: "City or locality. ",
  address_formatted: "Full mailing address. ",
  address_street_address: "Full street address. ",
  amr_0: "Authentication methods. "
};

export default {
  authorize,
  signOff,
  getAccessToken,
  getUserInfo,
  verifyToken,
  isTokenExpired,

  parseHash,
  generateRandomValue,
  flatten,

  CLAIMS_MAPPING
};
