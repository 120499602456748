import { useState } from 'react';

/**
 * Extended version of useState, but it returns toggle function instead of setValue.
 * It usefull when there is need to switch between two states, like `false` and `true`.
 * 
 * @param defaultValue - value to be initialized with
 * @returns An array containing
 *  - `value` (boolean): The current state
 *  - `toggleValue` (function): A function to toggle the state
 * 
 * @example
 * const [isOn, toggleIsOn] = useToggle();
 * 
 * return (
 *  <button onClick={toggleIsOn}>
 *    {isOn ? "Turn Off" : "Turn On"}
 *  </button>
 * )
 */
const useToggle = (defaultValue: boolean = false) => {
  const [value, setValue] = useState(defaultValue);

  const toggleValue = (value?: boolean) => setValue((prevValue) => value ?? !prevValue);

  return [value, toggleValue];
};

export default useToggle;
