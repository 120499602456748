import SpinnerProvider from './SpinnerProvider';
import SpinnerOverlay from './SpinnerOverlay';
import SpinnerContext from './spinner-context';

const Spinner = {
  Provider: SpinnerProvider,
  Overlay: SpinnerOverlay,
  Context: SpinnerContext,
};

export default Spinner;
